<template>
  <!--begin::Wrapper-->
  <div class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
    <!--begin::Form-->
    <Form class="form w-100" id="kt_login_signin_form">
      <!--begin::Heading-->
      <div class="text-center mb-10">
        <!--begin::Title-->
        <h1 class="text-dark mb-3">{{ $t("signin.title") }}</h1>
        <!--end::Title-->

        <!--begin::Link-->
        <div class="text-gray-400 fw-bold fs-4">
          {{ $t("signin.signup.title") }}
          <router-link to="/register" class="link-primary fw-bolder">
            {{ $t("signin.signup.link") }}
          </router-link>
        </div>
        <!--end::Link-->
      </div>
      <!--begin::Heading-->
      <div
        :class="`alert alert-${message.color}`"
        role="alert"
        v-if="message.show && message.text"
      >
        {{ message.text }}
      </div>
      <!--begin::Input group-->
      <div class="fv-row mb-10 form-group">
        <!--begin::Label-->
        <label class="form-label fs-6 font-weight-bolder text-dark">{{
          $t("signin.fields.email.label")
        }}</label>
        <!--end::Label-->

        <!--begin::Input-->
        <input
          class="form-control form-control-lg form-control-solid"
          type="text"
          name="email"
          ref="email"
          tabindex="1"
          v-model="form.email"
        />
        <!--end::Input-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="fv-row mb-10 form-group">
        <!--begin::Wrapper-->
        <div class="d-flex justify-content-between mt-n5">
          <!--begin::Label-->
          <label class="form-label font-weight-bolder text-dark fs-6 mb-0">{{
            $t("signin.fields.password.label")
          }}</label>
          <!--end::Label-->

          <!--begin::Link-->
          <router-link
            to="/password-reset"
            class="text-right link-primary fs-6 font-weight-bolder"
          >
            {{ $t("signin.actions.forget_password") }}
          </router-link>
          <!--end::Link-->
        </div>
        <!--end::Wrapper-->

        <!--begin::Input-->
        <input
          class="form-control form-control-lg form-control-solid"
          type="password"
          tabindex="2"
          name="password"
          ref="password"
          v-model="form.password"
          autocomplete="off"
        />
        <!--end::Input-->
      </div>
      <!--end::Input group-->

      <!--begin::Actions-->
      <div class="text-center">
        <!--begin::Submit button-->
        <v-btn
          color="primary"
          :loading="loading.signin"
          @click="signin"
          large
          elevation="0"
          class="btn btn-lg btn-primary w-100 mb-5"
          style="background-color: #3699FF;"
          ref="kt_login_signin_submit"
        >
          {{ $t("common.continue") }}
        </v-btn>
        <!--end::Submit button-->
      </div>
      <!--end::Actions-->
    </Form>
    <!--end::Form-->
  </div>
  <!--end::Wrapper-->
</template>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "login",
  data() {
    return {
      fv: null,
      state: "signin",
      form: {
        email: "",
        password: ""
      }
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
      loading: state => state.auth.loading,
      message: state => state.message.message
    }),
    ...mapGetters(["currentUser"])
  },
  mounted() {
    this.fv = formValidation(document.getElementById("kt_login_signin_form"), {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: this.$t("signin.fields.email.validation.empty")
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: this.$t("signin.fields.password.validation.empty")
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });
  },

  methods: {
    signin() {
      var email = this.form.email;
      var password = this.form.password;

      this.fv.validate().then(isValid => {
        if (isValid === "Valid") {
          // clear existing errors
          this.$store.dispatch(LOGOUT);

          // set spinner to submit button
          this.loading.signin = true;

          // send login request
          this.$store
            .dispatch(LOGIN, { email, password })
            // go to which page after successfully login
            .then(() => {
              this.loading.signin = false;
              this.$router.push({ name: "dashboard" });
            })
            .catch(() => {
              this.loading.signin = false;
            });
        } else {
          Swal.fire({
            title: "",
            text: this.$t("common.dialog_validate.content"),
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
        }
      });
    }
  }
};
</script>
